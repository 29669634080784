import { RouteConfig } from "aurelia-router";
import { IOfficeDto, IStaffDto } from "../../../../typings/project";
import { HttpService } from "../../../services/http-service";
import { autoinject } from "aurelia-framework";
import { ApplicationSettings } from "../../../services/settings-service";
import * as EmailValidator from 'email-validator';

@autoinject()
export class OfficeDetails {

    constructor(private readonly http: HttpService,
        private readonly appSettings: ApplicationSettings) {
    }

    office: IOfficeDto = <IOfficeDto>{};
    itSchoolCode: string;
    type: string;
    internalUser: boolean;

    staffTable: HTMLTableElement;
    staffSortButtons: HTMLDivElement;

    activate = async (params: any, routeConfig: RouteConfig) => {
        this.itSchoolCode = params.itSchoolCode;
        await this.loadOfficeDetails();
        routeConfig.navModel.setTitle(this.office.name);
    }

    attached() {
        this.scrollToTopOfOfficeDisplay();
    }

    loadOfficeDetails = async () => {
        this.office = await this.http.get('/Office/GetOfficeDetails', { param: this.itSchoolCode });
        this.type = this.office.isDivision ? 'Division' : 'Branch';
        this.internalUser = this.appSettings.settings.isInternalUser;
    }

    isValidEmail = (email: string) => {
        return email && EmailValidator.validate(email);
    }

    scrollToTopOfOfficeDisplay() {
        $([document.documentElement, document.body]).animate({
            scrollTop: $("main").offset().top
        }, 1000);
    }

    fullName(row: IStaffDto) {
        return `${row.lastName}, ${row.firstName}`;
    }

    sort(property: string, $event: Event) {
        var ths = this.staffTable.querySelectorAll('th');
        var descClass = 'aut-desc';
        var ascClass = 'aut-asc';

        var btn = $event.target as HTMLElement;
        this.resetBtnSortIndicator(btn);

        if (btn.classList.contains(ascClass)) {
            btn.classList.remove(ascClass);
            btn.classList.add(descClass);
        } else if (btn.classList.contains(descClass)) {
            btn.classList.remove(descClass);
        } else {
            btn.classList.add(ascClass);
        }

        for (var i = 0; i < ths.length; i++) {
            var th = ths.item(i);
            if (th.dataset.sort == 'fullName' && property == 'fullName') {
                th.click();
            } else if (th.dataset.sort == 'primaryRole' && property == 'primaryRole') {
                th.click();
            } else if (th.dataset.sort == 'phone' && property == 'phone') {
                th.click();
            }
        }
    }

    resetBtnSortIndicator(btnToIgnore: HTMLElement) {
        var btns = this.staffSortButtons.children;
        var descClass = 'aut-desc';
        var ascClass = 'aut-asc';

        for (var i = 0; i < btns.length; i++) {
            var btn = btns.item(i);

            if (btn == btnToIgnore) {
                continue;
            }
            btn.classList.remove(descClass);
            btn.classList.remove(ascClass);
        }
    }
}