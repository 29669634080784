import {
    Aurelia,
    PLATFORM,
    FrameworkConfiguration,
    InlineViewStrategy
} from 'aurelia-framework';

import {Configure} from 'aurelia-google-maps'

import {HttpClient, json} from 'aurelia-fetch-client';

import * as Fuse from 'fuse.js';

import 'polyfill-array-includes';
import 'whatwg-fetch';

import { HttpService } from "./services/http-service";



import { ISettings } from '../typings/project';

import "../styles/app.scss";


export async function configure(aurelia: Aurelia): Promise<void> {

    if(navigator.userAgent.indexOf('MSIE')!==-1){
        //do not start if IE < 11
        return;
    }

    var http = new HttpService(new HttpClient());
    

    try{
        var settings = await http.get<ISettings>("/System/Settings"); //this should be loaded via API
       // var settings:ISettings = { buildVersion:'0.1',environment:'Dev',isAppOffline:false,isInternalUser:true }; 
        start(settings);
    }
    catch(err){
        //app is offline
        var dt: ISettings = <ISettings>{
            isAppOffline:true
        }
        await start(dt);
    }

    async function start(data:ISettings):Promise<void>{

        aurelia.container.registerInstance("appSettings", data);
        aurelia.use.standardConfiguration()
       
        .feature(PLATFORM.moduleName('components/index'))
        .feature(PLATFORM.moduleName('value-converters/index'))
        .plugin(PLATFORM.moduleName('au-table'))
        
        //FIXME image resources below should be hosted as per https://www.npmjs.com/package/aurelia-google-maps#configuring-for-use
        .plugin(PLATFORM.moduleName('aurelia-google-maps'), (config: any) => {
            config.options({
                apiKey: data.googleMapsApiKey, 
                apiLibraries: 'drawing,geometry', 
                options: { panControl: true, panControlOptions: { position: 9 } },
                region: 'AU',
                markerCluster: {
                    enable: false,
                    src: 'https://cdn.rawgit.com/googlemaps/v3-utility-library/99a385c1/markerclusterer/src/markerclusterer.js', 
                    imagePath: 'https://cdn.rawgit.com/googlemaps/v3-utility-library/tree/master/markerclusterer/images/m', 
                    imageExtension: 'png',
                }
            });
        })
        
        ;

        if (process.env.NODE_ENV !== 'production') {
            aurelia.use.developmentLogging();
        }

        
        try{
            await aurelia.start();
            await aurelia.setRoot(PLATFORM.moduleName('app'));
        }
        catch(error){
            console.error(error);
        }
    }
}
