
import { HttpClient, json } from "aurelia-fetch-client";
import { autoinject } from "aurelia-framework";
import { EnvironmentVars } from 'environment';
import * as param  from 'jquery-param'

@autoinject()
export class HttpService{
    constructor(
        public http:HttpClient
    ){
        http.configure(config => {
            config
            .withBaseUrl(EnvironmentVars.baseUrl)
            .withDefaults({
                credentials: EnvironmentVars.credentialsPolicy,
                headers: {
                    'Accept' : 'application/json',
                    'X-Requested-With': 'Fetch'
                }
            });
        });
    }

    async get<T>(url:string,params:any = null):Promise<T>{
        
        url = this.appendSlash(url);

        var output:T = null;

        if (params!=null){
            var url = url + "?" + this.buildParams(params)
        }

        try{
            var result = await this.http.get(url);
            output =  await result.json();   
        }
        catch(e){
            console.error(e);
        }

        return output;
    }

    private appendSlash(url:string):string{
        if (url.indexOf("/")!=0)
        {
            return "/" +url;
        }
        return url;
    }

    async post<T>(url:string,data:any):Promise<T>{
        
        url = this.appendSlash(url);

        var result = await this.http.fetch(url, {
            method: "post",
            body: json(data)
        });
        if (result.status!=200){
            throw new Error(result.statusText);
        }
        const contentType = result.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1)
            return await result.json();
        return 
    }

    downloadFile(url:string, params:any = null){
        if (params!=null){
            var url = url + "?" + param(params);
        }
        (<any>window).location = EnvironmentVars.baseUrl + url;
    }

    private buildParams(obj:any):string {
        var s:any = [];
        var add = function (k:any, v:any) {
            v = typeof v === 'function' ? v() : v;
            v = v === null ? '' : v === undefined ? '' : v;
            s[s.length] = encodeURIComponent(k) + '=' + encodeURIComponent(v);
        };
        var buildParams = function (prefix:any, obj:any) {
            var i, len, key;
            if (!obj)
                return;
                
            if (prefix) {
                if (Array.isArray(obj)) {
                    for (i = 0, len = obj.length; i < len; i++) {
                        buildParams(
                            prefix + '[' + (typeof obj[i] === 'object' && obj[i] ? i : i) + ']',
                            obj[i]
                        );
                    }
                } else if (String(obj) === '[object Object]') {
                    for (key in obj) {
                        buildParams(prefix + '[' + key + ']', obj[key]);
                    }
                } else {
                    add(prefix, obj);
                }
            } else if (Array.isArray(obj)) {
                for (i = 0, len = obj.length; i < len; i++) {
                    add(obj[i].name, obj[i].value);
                }
            } else {
                for (key in obj) {
                    buildParams(key, obj[key]);
                }
            }
            return s;
        };

        return buildParams('', obj).join('&');
    }
}