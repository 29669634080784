import { IStaffFilter, IStaffSearchResponseDto } from './../../../typings/project';
import { HttpService } from './../http-service';
import { autoinject } from 'aurelia-framework';

@autoinject()
export class StaffDataService {

    constructor(
        private readonly http: HttpService
    ) { }
    

    async searchStaff(staffFilter: IStaffFilter): Promise<IStaffSearchResponseDto> {
        return await this.http.post<IStaffSearchResponseDto>('/Staff/SearchStaff', staffFilter);
    }
}