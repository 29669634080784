import { ISchoolFilter } from "../app";
import { singleton } from "aurelia-framework";

@singleton()
export class FilterService {
    schoolFilter: ISchoolFilter;
    filterCallBack:Function;
    clearCallBack:Function;

    constructor() {
        this.initFilter();
    }

    get hasAnyFilter():boolean{
        return !(
            this.isEmpty(this.schoolFilter.electorate) &&
            this.isEmpty(this.schoolFilter.decsRegion) &&
            this.isEmpty(this.schoolFilter.schoolName) &&
            this.isEmpty(this.schoolFilter.schoolType) &&
            this.schoolFilter.isGovernment ==null
        )
    }

    private initFilter(){
        this.schoolFilter = <ISchoolFilter>{
            isGovernment:null
        };
    }

    clear(){
       
        this.initFilter();
        if (this.clearCallBack!=null){
            this.clearCallBack();
        }
    }
    
    private isEmpty(value:any):boolean{
        return (value == null || value.length === 0);
    } 

    doSearch(){
        if (this.filterCallBack!=null)
            this.filterCallBack();
    }
}