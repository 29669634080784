import { autoinject, computedFrom, observable } from "aurelia-framework";
import { Router, RouteConfig } from "aurelia-router";
import {
  ISchoolDto,
  ISchoolInternalDto,
  IStaffDto
} from "../../../../typings/project";
import { Marker } from "aurelia-google-maps";
import { HttpService } from "../../../services/http-service";
import { ApplicationSettings } from "../../../services/settings-service";
import { Paths } from "../../../paths";
import * as EmailValidator from "email-validator";

@autoinject()
export class SchoolDetails {
  itSchoolCode: string;
  school: ISchoolDto = <ISchoolDto | ISchoolInternalDto>{};

  imageUrl: string;
  mapMarkers: Marker[] = [];
  internalUser: boolean;
  @observable search: string = null;
  initialStaffs: IStaffDto[];

  additionalRoleClasses: Record<string, string> = {
    "SAMS Admin": "sd-sams-admin",
    ICTC: "sd-ictc",
    "Finance Officer": "sd-finance-officer",
    Librarian: "sd-librarian"
  };

  staffTable: HTMLTableElement;
  staffSortButtons: HTMLDivElement;

  constructor(
    private readonly router: Router,
    private readonly httpService: HttpService,
    private readonly appSettings: ApplicationSettings
  ) { }

  activate = async (params: any, routeConfig: RouteConfig) => {
    this.itSchoolCode = params.itSchoolCode;
    await this.loadSchoolData();
    routeConfig.navModel.setTitle(this.school.name);
    this.initialStaffs = (this.school as ISchoolInternalDto).schoolStaff;
  };

  async attached() {
    this.scrollToTopOfSchoolDisplay();

    this.internalUser = this.appSettings.settings.isInternalUser;
    this.imageUrl =
      this.appSettings.apiUrl +
      Paths.getSchoolImagePath +
      encodeURI(this.itSchoolCode);

    this.mapMarkers.push({
      latitude: this.school.lat,
      longitude: this.school.long,
      title: this.school.name
    });

  }

  async loadSchoolData() {
    if (this.appSettings.settings.isInternalUser) {
      this.school = await this.httpService.get<ISchoolInternalDto>(Paths.getSchoolPath, {
        itSchoolCode: this.itSchoolCode
      });
    } else {
      this.school = await this.httpService.get<ISchoolDto>(Paths.getSchoolPath, {
        itSchoolCode: this.itSchoolCode
      });
    }
  }

  @computedFrom(
    "school.preSchool",
    "school.primarySchool",
    "school.middleSchool",
    "school.seniorSchool",
    "school.faftSchool"
  )
  get schoolType(): string {
    let types: string[] = [];
    if (this.school.faftSchool) types.push("FAFT School");
    if (this.school.preSchool) types.push("Preschool");
    if (this.school.primarySchool) types.push("Primary School");
    if (this.school.middleSchool) types.push("Middle School");
    if (this.school.seniorSchool) types.push("Senior School");
    return types ? types.join(", ") : "";
  }

  doSearch() {
    this.router.navigateToRoute("school-list");
  }

  isValidEmail = (email: string) => {
    return email && EmailValidator.validate(email);
  };

  scrollToTopOfSchoolDisplay() {
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $("main").offset().top
      },
      1000
    );
  }

  clearSearch() {
    //this will trigger SearchChanged
    this.search = null;
  }

  fullName(row: IStaffDto) {
    return `${row.lastName}, ${row.firstName}`;
  }


  sort(property: string, $event: Event) {
    var ths = this.staffTable.querySelectorAll('th');
    var descClass = 'aut-desc';
    var ascClass = 'aut-asc';

    var btn = $event.target as HTMLElement;
    this.resetBtnSortIndicator(btn);

    if (btn.classList.contains(ascClass)) {
      btn.classList.remove(ascClass);
      btn.classList.add(descClass);
    } else if (btn.classList.contains(descClass)) {
      btn.classList.remove(descClass);
    } else {
      btn.classList.add(ascClass);
    }

    for (var i = 0; i < ths.length; i++) {
      var th = ths.item(i);
      if (th.dataset.sort == 'fullName' && property == 'fullName') {
        th.click();
      } else if (th.dataset.sort == 'primaryRole' && property == 'primaryRole') {
        th.click();
      } else if (th.dataset.sort == 'phone' && property == 'phone') {
        th.click();
      }
    }
  }

  resetBtnSortIndicator(btnToIgnore: HTMLElement) {
    var btns = this.staffSortButtons.children;
    var descClass = 'aut-desc';
    var ascClass = 'aut-asc';

    for (var i = 0; i < btns.length; i++) {
      var btn = btns.item(i);

      if (btn == btnToIgnore) {
        continue;
      }
      btn.classList.remove(descClass);
      btn.classList.remove(ascClass);
    }
  }
}
