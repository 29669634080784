import { autoinject } from "aurelia-framework";
import { HttpService } from "../../../services/http-service";
import { IDivisionForListDto, IBranchForListDto } from "../../../../typings/project";
import { Router } from "aurelia-router";
import * as EmailValidator from 'email-validator';

@autoinject()
export class OfficeList
{
    offices: IBranchForListDto[] = [];
    
    constructor(private readonly http: HttpService,
        private readonly router: Router)
    {

    }
 
    activate = async () =>
    {
        const branches: IDivisionForListDto[] = await this.http.get<IDivisionForListDto[]>("/Office/GetOfficeList");

        branches.forEach(element => {
            this.offices.push(element);
            this.offices = this.offices.concat(element.offices);
        });
    }
 
    goToOffice = (office: IBranchForListDto) => 
    {
        this.router.navigateToRoute("office-details", {itSchoolCode: office.itSchoolCode});
    }

    isValidEmail = (email: string) =>
    {
        return email && EmailValidator.validate(email);
    }

}