import { ApplicationSettings } from './../../services/settings-service';
import { IStaffFilter, IStaffSearchResponseDto, IStaffForListDto } from './../../../typings/project';
import { StaffDataService } from './../../services/data-services/staff-data-service';
import { autoinject, BindingEngine, Disposable } from 'aurelia-framework';

@autoinject()
export class Staff {

    staffList: IStaffSearchResponseDto = {} as IStaffSearchResponseDto;

    staffFilter: IStaffFilter = {
        search: '',
        pageSize: 20,
        currentPage: 1,
        sortKey: '',
        sortOrder: ''
    };

    staffTable: HTMLTableElement;
    staffSortButtons: HTMLDivElement;

    additionalRoleClasses: Record<string, string> = {
        "SAMS Admin": "sd-sams-admin",
        ICTC: "sd-ictc",
        "Finance Officer": "sd-finance-officer",
        Librarian: "sd-librarian"
    };

    subscriptions: Disposable[] = [];

    constructor(
        private staffDataService: StaffDataService,
        private appSettings: ApplicationSettings,
        private bindingEngine: BindingEngine
    ) {
        (<any>window).StaffTableController = this;
    }

    bind() {
        this.loadStaff();

        this.subscriptions.push(this.bindingEngine.propertyObserver(this.staffFilter, 'search').subscribe(async (newValue, oldValue) => {
            if (oldValue == newValue) return;

            await this.loadStaff();
        }));

        this.subscriptions.push(this.bindingEngine.propertyObserver(this.staffFilter, 'pageSize').subscribe(async (newValue, oldValue) => {
            if (oldValue == newValue) return;

            await this.loadStaff();
        }));

        this.subscriptions.push(this.bindingEngine.propertyObserver(this.staffFilter, 'currentPage').subscribe(async (newValue, oldValue) => {
            if (oldValue == newValue) return;

            await this.loadStaff();
        }));

        this.subscriptions.push(this.bindingEngine.propertyObserver(this.staffFilter, 'sortKey').subscribe(async (newValue, oldValue) => {
            if (oldValue == newValue) return;

            await this.loadStaff();
        }));

        this.subscriptions.push(this.bindingEngine.propertyObserver(this.staffFilter, 'sortOrder').subscribe(async (newValue, oldValue) => {
            if (oldValue == newValue) return;

            await this.loadStaff();
        }));
    }

    loadStaff = async () => {
        if (!this.isInternalUser()) return;

        this.staffList = await this.staffDataService.searchStaff(this.staffFilter);
    }

    clearSearch() {
        this.staffFilter.search = "";
    }

    isInternalUser() {
        return this.appSettings.settings.isInternalUser;
    }

    sort($event: Event) {
        var descClass = 'aut-desc';
        var ascClass = 'aut-asc';

        enum sortOrder {
            ASC = "ASC",
            DESC = "DESC"
        };

        // debugger;
        var targetEl = $event.target as HTMLElement;
        if (!targetEl) return;

        this.resetTableSortClasses(targetEl as HTMLTableHeaderCellElement)

        this.staffFilter.sortKey = targetEl.dataset.sort;

        if (targetEl.classList.contains(ascClass)) {
            this.staffFilter.sortOrder = sortOrder.DESC;
            targetEl.classList.add(descClass);
            targetEl.classList.remove(ascClass);
        } else if (targetEl.classList.contains(descClass)) {
            this.staffFilter.sortOrder = '';
            targetEl.classList.remove(descClass);
        } else {
            this.staffFilter.sortOrder = sortOrder.ASC;
            targetEl.classList.add(ascClass);
        }
    }

    resetTableSortClasses(currentTh: HTMLTableHeaderCellElement) {
        var descClass = 'aut-desc';
        var ascClass = 'aut-asc';

        var ths = (this.staffTable as HTMLElement).querySelectorAll('th');
        for (var i = 0; i < ths.length; i++) {
            var th = ths.item(i);
            if (currentTh.dataset.sort == th.dataset.sort) {
                continue;
            }

            th.classList.remove(descClass);
            th.classList.remove(ascClass);
        }
    }


    detached() {
        while (this.subscriptions.length > 0) {
            this.subscriptions.pop().dispose();
        }
    }


    sortBtn(property: string, $event: Event) {
        var ths = this.staffTable.querySelectorAll('th');
        var descClass = 'aut-desc';
        var ascClass = 'aut-asc';

        var btn = $event.target as HTMLElement;
        this.resetBtnSortIndicator(btn);

        if (btn.classList.contains(ascClass)) {
            btn.classList.remove(ascClass);
            btn.classList.add(descClass);
        } else if (btn.classList.contains(descClass)) {
            btn.classList.remove(descClass);
        } else {
            btn.classList.add(ascClass);
        }

        for (var i = 0; i < ths.length; i++) {
            var th = ths.item(i);
            if (th.dataset.sort == 'lastName' && property == 'lastName') {
                th.click();
            } else if (th.dataset.sort == 'primaryRole' && property == 'primaryRole') {
                th.click();
            } else if (th.dataset.sort == 'schoolName' && property == 'schoolName') {
                th.click();
            } else if (th.dataset.sort == 'phone' && property == 'phone') {
                th.click();
            }
            else if (th.dataset.sort == 'location' && property == 'location') {
                th.click();
            }
        }
    }

    resetBtnSortIndicator(btnToIgnore: HTMLElement) {
        var btns = this.staffSortButtons.children;
        var descClass = 'aut-desc';
        var ascClass = 'aut-asc';

        for (var i = 0; i < btns.length; i++) {
            var btn = btns.item(i);

            if (btn == btnToIgnore) {
                continue;
            }
            btn.classList.remove(descClass);
            btn.classList.remove(ascClass);
        }
    }
}