//import "./resizable-panel.scss";
import "jquery-resizable-dom";
import { autoinject } from "aurelia-framework";

@autoinject
export class ResizablePanelCustomAttribute {
    
    constructor(private readonly element:Element){
       $(()=>{
        $(element)
            .addClass("resizeable")
            .resizable({
                handleSelector: ".splitter",
                resizeHeight: false
            });
        
       });
    }
}
