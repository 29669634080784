import { HttpService } from "../../services/http-service";
import { ApplicationSettings } from "../../services/settings-service";
import { autoinject } from "aurelia-framework";

@autoinject()
export class Downloads
{
    
    isInternalUser :boolean;

    constructor(private readonly http: HttpService,
        private readonly appSettings: ApplicationSettings)
    {

    }

    activate = async () =>
    {
        this.isInternalUser = this.appSettings.settings.isInternalUser;
    }


    downloadOfficeDetails = async () => 
    {
        await this.http.downloadFile("/Office/GetOfficeListAsCsv");
    }

    downloadSchoolList = async () => 
    {
        await this.http.downloadFile("/System/GetSchoolListAsCsv");
    }

    downloadPublicSchoolList = async () => 
    {
        await this.http.downloadFile("/System/GetPublicSchoolListAsCsv");
    }

}