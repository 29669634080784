import { IAddress } from "../../typings/project";


export class AddressValueConverter {
  toView(value?:IAddress) {
   

    if(value)
    {
      let parts: string[] = [];      

      if(value.description && value.description.trim() != "")
      {
        parts.push(value.description.trim());
      }

      if(value.state && value.state.trim() != "")
      {
        parts.push(value.state.trim());
      }

      if(value.postCode && value.postCode.trim() != "")
      {
        parts.push(value.postCode.trim());
      }

      return parts.join(", ");

    }

    return "";

  }
}