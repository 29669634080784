import { FrameworkConfiguration } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-framework'; 


export function configure(aurelia: FrameworkConfiguration) {
    aurelia.globalResources([
        PLATFORM.moduleName("./banner/banner"),
        PLATFORM.moduleName("./resizable-panel/resizable-panel"),
        PLATFORM.moduleName("./school-filter/school-filter"),
        PLATFORM.moduleName("./back-to-top/back-to-top"),
     
    ]);
}
