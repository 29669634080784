import { autoinject, bindable } from 'aurelia-framework';
import 'jquery';

@autoinject()
export class BackToTop {

    public static ScrollEventName = 'scroll';
    public static MinimumWindowScroll = 50;

    @bindable iconClass = 'icon-up-big';

    constructor(private element: Element) { }

    attached() {
        window.addEventListener(BackToTop.ScrollEventName, this.onScrollChange);
    }

    detached() {
        window.removeEventListener(BackToTop.ScrollEventName, this.onScrollChange);
    }

    click() {
        $('body,html').animate({ scrollTop: 0 }, 500);
    }

    private onScrollChange = () => {
        const element = $(this.element).children().first();

        //css check so it doesn't keep calling show
        if (window.pageYOffset >= BackToTop.MinimumWindowScroll && element.css('display') === 'none') {
            element.show();
        } else if(window.pageYOffset <= BackToTop.MinimumWindowScroll && element.css('display') === 'block') {
            element.hide();
        }
    }
}