
import { autoinject, PLATFORM } from "aurelia-framework";
import { RouterConfiguration, RouteConfig, Router } from "aurelia-router";

@autoinject()
export class Offices {

    router: Router = <Router>{};
    
    constructor() {
     
    }

    configureRouter(config: RouterConfiguration, router: Router): void {

        this.router = router;
        
        config.map((<RouteConfig[]>([
            {
                route: '',
                name: 'office-list',
                moduleId: PLATFORM.moduleName('./office-list/office-list'),
                title: 'List',
                nav: false
              },
            {
                route: 'details/:itSchoolCode',
                name: 'office-details',
                moduleId: PLATFORM.moduleName('./office-details/office-details'),
                title: 'Office Details',
                nav: false
              },
            ]))
        );
    }    
}