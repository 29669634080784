import { ISettings } from "../../typings/project";
import { inject, autoinject } from "aurelia-framework";
import { HttpClient } from "aurelia-fetch-client";
import { EnvironmentVars } from 'environment';


@inject('appSettings')
export class ApplicationSettings{
    apiUrl:string;

    constructor(public readonly settings: ISettings){
        this.apiUrl = EnvironmentVars.baseUrl;
    }    
}