import { ISchoolFilter } from "../../app";
import { bindable, computedFrom, autoinject } from "aurelia-framework";
import { ApplicationSettings } from "../../services/settings-service";

import { FilterService } from "../../services/filter-service";

@autoinject
export class SchoolFilter {

    
    /* Shows/Hides filter fields */
    hideDropdowns: boolean = true;

    /* Drop downs */
    types: string[];
    electorates: string[];
    regions: string[];

    constructor(
        private readonly appSettings: ApplicationSettings,
        private readonly filterService: FilterService,
        ) {
            if(filterService.hasAnyFilter)
            {
                this.hideDropdowns = false;
            }
            
    }

    attached() {
        this.types = this.appSettings.settings.filterDropdowns.types;
        this.electorates = this.appSettings.settings.filterDropdowns.electorates;
        this.regions = this.appSettings.settings.filterDropdowns.regions;
    }

    clearFilter(){
        this.filterService.clear();
    }

    doSearch(){
        this.filterService.doSearch();
    }


    toggleDropdowns() {
        this.hideDropdowns = !this.hideDropdowns;
    }
}