
import { autoinject, PLATFORM } from "aurelia-framework";
import { RouterConfiguration, RouteConfig, Router } from "aurelia-router";

@autoinject()
export class Schools {

    router: Router = <Router>{};
    
    constructor() {
     
    }

    configureRouter(config: RouterConfiguration, router: Router): void {

        this.router = router;
        
        config.map((<RouteConfig[]>([
            {
                route: '',
                name: 'school-list',
                moduleId: PLATFORM.moduleName('./school-list/school-list'),
                title: 'List',
                nav: false
              },
            {
                route: 'details/:itSchoolCode',
                name: 'details',
                moduleId: PLATFORM.moduleName('./school-details/school-details'),
                title: 'School Details',
                nav: false
              },
            ]))
        );
    }    
}