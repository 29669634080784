import { HttpService } from './services/http-service';

import { RouterConfiguration, Router, RouteConfig, NavModel, NavigationInstruction, Next } from 'aurelia-router';
import { inject, PLATFORM, autoinject, Disposable, computedFrom } from 'aurelia-framework';
import { ApplicationSettings } from './services/settings-service';


export interface ISchoolFilter {
  schoolName: string;
  schoolType: string;
  decsRegion: string;
  electorate: string;
  isGovernment: boolean;
}

@autoinject
export class App {

  buildVersion: string;
  router: Router = <Router>{};

  filter: ISchoolFilter = { schoolName: null, schoolType: null, decsRegion: null, electorate: null, isGovernment: null };


  constructor(
    private readonly appSettings: ApplicationSettings
  ) {
    this.buildVersion = appSettings.settings.buildVersion;
  }

  get hideHeaderAndFooter(): boolean {
    return this.currentRouteName == "apiDown";
  }

  get currentRouteName(): string | undefined {
    if (this.router == null || this.router.currentInstruction == null || this.router.currentInstruction.config == null)
      return '';
    return this.router.currentInstruction.config.name;
  }

  get currentYear(): number {
    var dt = new Date();
    return dt.getFullYear();
  }


  configureRouter(config: RouterConfiguration, router: Router): void {

    this.router = router;
    config.title = 'DoE';
    var routeConfig: RouteConfig[] = [
      {
        route: '',
        redirect: 'schools'
      },
      {
        route: 'schools',
        name: 'schools',
        moduleId: PLATFORM.moduleName('pages/schools/schools'),
        title: 'Schools',
        nav: true
      },
      {
        route: 'offices',
        name: 'offices',
        moduleId: PLATFORM.moduleName('pages/offices/offices'),
        title: 'Offices',
        nav: false
      },
      {
        route: 'downloads',
        name: 'downloads',
        moduleId: PLATFORM.moduleName('pages/downloads/downloads'),
        title: 'Downloads',
        nav: false
      },
      {
        route: 'apiDown',
        name: 'apiDown',
        moduleId: PLATFORM.moduleName('pages/api-down/api-down'),
        title: 'Application is Offline',
        nav: false
      }
    ];

    if (this.appSettings.settings.isInternalUser) {
      routeConfig.push({
        route: 'staff',
        name: 'staff',
        moduleId: PLATFORM.moduleName('pages/staff/staff'),
        title: 'Staff',
        nav: false
      })
    }

    config.map(routeConfig);

    config.mapUnknownRoutes({ route: 'unknown', redirect: '#/' }); //https://stackoverflow.com/questions/33280224/redirect-within-mapunknownroutes-in-aurelia

    //bellow its absolute shit, we should be able to use route.navigateTo, but it does not work from here, 
    //and I dont know another event that runs after router configuration (activate runs before?!?!?!) 
    if (this.appSettings.settings.isAppOffline) {
      location.assign('#/apiDown');
    }

  }
}
