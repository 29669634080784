import { ApplicationSettings } from "../../services/settings-service";
import { autoinject } from "aurelia-framework";

@autoinject()
export class Banner {
    bannerImageUrl: string;

    classShowMenu:string = "";
    
    constructor(private readonly appSettings:ApplicationSettings){
        this.bannerImageUrl = appSettings.apiUrl + "/System/GetBannerImage";
    }

    toggleMenu(){
        if (this.classShowMenu)
        {
            this.classShowMenu = "";
        }
        else{
            this.classShowMenu = "show";
        }
    }

    isInternalUser() {
        return this.appSettings.settings.isInternalUser;
    }
}