import { autoinject, computedFrom } from "aurelia-framework";
import { ISchoolsForListDto } from "../../../../typings/project";
import { Router } from "aurelia-router";
import { StringFormatValueConverter } from "../../../value-converters/string-format-value-converter";
import { FilterService } from "../../../services/filter-service";
import { HttpService } from "../../../services/http-service";
import { Paths } from "../../../paths";

import { BindingSignaler } from 'aurelia-templating-resources';

@autoinject()
export class SchoolList {


    schoolList: ISchoolsForListDto[];
    alphabet: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    groupedSchools: any = [];
    hasResults: boolean = true;

    constructor(
        private readonly router: Router,
        private readonly filterService: FilterService,
        private readonly httpService: HttpService,
        private readonly bindingSignaler: BindingSignaler
    ) {

        (<any>window)["SchoolList"] = this;
    }




    async attached() {
        this.schoolList = await this.httpService.get<ISchoolsForListDto[]>(Paths.getAllSchoolsPath);

        this.groupSchools();
        this.bindingSignaler.signal("reloadList");

        this.filterService.filterCallBack = () => {
            this.doSearch();
        }

        this.filterService.clearCallBack = () => {
            this.groupSchools();
            this.bindingSignaler.signal("reloadList");
            this.hasResults = this.filteredSchools && this.filteredSchools.length > 0;
        }
    }

    getSchoolsForView(letter: string) {
        return this.groupedSchools[letter];
    }

    get filteredSchools(): ISchoolsForListDto[] {
        var output = this.schoolList;
        if (this.filterService.schoolFilter.schoolName) {
            output = output.filter(i => i.schoolName.toLowerCase().indexOf(this.filterService.schoolFilter.schoolName.toLowerCase()) > -1)
        }
        if (this.filterService.schoolFilter.isGovernment != null) {
            output = output.filter(i => i.isGovernment == this.filterService.schoolFilter.isGovernment);
        }

        if (this.filterService.schoolFilter.schoolType) {
            output = output.filter(i => i.schoolType == this.filterService.schoolFilter.schoolType);
        }

        if (this.filterService.schoolFilter.electorate) {
            output = output.filter(i => i.electorate == this.filterService.schoolFilter.electorate);
        }

        if (this.filterService.schoolFilter.decsRegion) {
            output = output.filter(i => i.decsRegion == this.filterService.schoolFilter.decsRegion);
        }

        return output;
    }

    scrollToTopOfList() {
        this.scrollTo($("#search-panel-container"));
    }

    scrollToLetter(letter: string) {
        let elm = $("#letter-" + letter);
        this.scrollTo($("#letter-" + letter));
    }

    private scrollTo(elm: JQuery) {
        if (elm.length > 0) {
            $([document.documentElement, document.body]).animate({
                scrollTop: elm.offset().top
            }, 1000);
        }
    }

 
    doSearch() {
        this.groupSchools();
        this.bindingSignaler.signal("reloadList");
        if (this.filteredSchools && this.filteredSchools.length > 0) {
            this.scrollToTopOfList();
            this.hasResults = true;
        }
        else {
            this.hasResults = false;
        }
    }


    groupSchools() {
        this.alphabet.forEach(letter => {
            this.groupedSchools[letter] = this.filteredSchools.filter(j => j.schoolName.indexOf(letter) == 0);
        });
    }

    hasSchools(letter: string) {
        var schools = this.groupedSchools[letter];
        return schools && schools.length > 0;
    }


    schoolDetails(school: ISchoolsForListDto) {
        this.router.navigateToRoute("details", { itSchoolCode: school.itSchoolCode });
    }
}




